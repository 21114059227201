import React from 'react';
import Helmet from 'react-helmet';
import { LayoutProps, withLayout } from '../../components/Layout';
import { LineTitle } from '../../components/LineTitle/LineTitle.component';
import { FixedMasthead } from '../../components/Masthead/FixedMasthead.component';
import { PageHeader } from '../../components/PageHeader/PageHeader.component';
import CallToContact from '../../components/CallToContact/CallToContact.component';
import {
    AndDivider,
    Blockquote,
    CheckList,
    Constrain,
    GuthenHeadingLevel3,
    GuthenHeadingLevel4,
    LinkArrow,
    LinkText,
    pullBackExtraWide,
    pullNarrowBackExtraWide
} from '../../components/ui';
import { QuerySiteArgs } from '../../generated/graphql-types';
import styled from '../../styled-components';
import { media, mediaMax } from '../../utilities';

import checklistIllustration from './images/checklist.svg';
import improveAProcessIllustrationWhite from './images/improve-a-process-illustration-white.svg';

import puzzle from './images/puzzle.svg';
import { BookACall } from '../../components/BookACall/BookACall';
import { SwellWrapper } from '../../components/SwellWrapper/SwellWrapper.component';
import { ShownOnDesktop } from '../../components/ui/ShownOnDesktop';
import { CTALink, DarkCTA } from '../../components/CTAs/DarkCTA';
import socialImage from './images/how-we-fill-a-gap-social.png';
import { EvidencePullout } from '../../components/EvidencePullout/EvidencePullout';

// illustrations
import thomsonLocalIllustration from '../../../data/case-studies/thomson-local-myorderbuilder/thomson-local-illustration.svg';
import telensaIllustration from '../../../data/case-studies/telensa-planet-street-lighting-system/telensa-illustration.svg';
import indevcoIllustration from '../../../data/case-studies/indevco-customer-portal/indevco-illustration.svg';

const BannerHeader = styled.div`
    padding: 1.5rem 0;
    background-color: ${({ theme }) => theme.colors.deepBlue.color};
    color: #fff;

    .inner {
        ${media.l`
            margin-right: ${pullBackExtraWide};
            display: flex;
            justify-content: space-between;
        `}

        ${media.xxl`
            margin-right: ${pullNarrowBackExtraWide};
        `}
    }

    ${LineTitle} {
        font-weight: 500;
    }

    ${LinkText} {
        font-weight: normal;
    }
`;

const RelatableHeading = styled.div`
    display: flex;
    flex-direction: column-reverse;

    .illustration {
        margin-bottom: 3rem;
    }

    h2 {
        margin-bottom: 1rem;
        font-size: ${({ theme }) => theme.fontNew.primary.size.level5.size};

        ${media.xl`
            font-size: ${({ theme }) => theme.fontNew.primary.size.level3.size};
            margin-bottom: 2rem;
            line-height: ${({ theme }) =>
                theme.fontNew.primary.size.level3.lineHeight};
        `}
    }

    p {
        font-size: ${({ theme }) => theme.fontNew.primary.size.level6.size};

        ${media.xl`
            font-size: ${({ theme }) => theme.fontNew.primary.size.level4.size};
            line-height: ${({ theme }) =>
                theme.fontNew.primary.size.level4.lineHeight};
        `}
    }

    ${media.m`
        display: flex;
        flex-direction: row;
        align-items: center;

        & > * {
            flex: 1;
        }

        &:last-child {
            flex-direction: row-reverse;
            .illustration {
                padding: 0 7rem 0 0;
            }
        }

        .illustration {
            flex: 1.5;
            padding: 0 0 0 7rem;
        }
    `}

    ${media.xl`
        .illustration {
            flex: 1;
            margin-right: ${pullBackExtraWide};
        }

        &:last-child {
            .illustration {
                margin-right: 0;
                margin-left: ${pullBackExtraWide};
            }
        }
    `}
`;

const Header = styled.section`
    margin-bottom: 6rem;

    ${media.m`
        margin-bottom: 12rem;
    `}
`;

const HighlightHeader = styled.h2`
    font-size: ${({ theme }) => theme.fontNew.primary.size.level4.size};
    line-height: ${({ theme }) => theme.fontNew.primary.size.level4.lineHeight};
    margin-bottom: 8rem;

    mark {
        background-color: ${({ theme }) => theme.colors.electricBlue.color};
        color: ${({ theme }) => theme.colors.deepBlue.color};
        line-height: 1.2;
    }

    ${media.xl`
        font-size: ${({ theme }) => theme.fontNew.primary.size.level1.size};
        line-height: ${({ theme }) =>
            theme.fontNew.primary.size.level1.lineHeight};
    `}
`;

const TitleHighlight = styled.div`
    position: relative;
    white-space: nowrap;
    padding-right: 1rem;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: -100vw;
        background-color: ${({ theme }) => theme.colors.orange.color};
        z-index: -1;
    }
`;

const WhatWeDoSection = styled.section`
    margin-bottom: 5rem;

    ${media.m`
        margin-bottom: 10rem;
    `}

    .header {
        ${TitleHighlight} {
            display: inline-block;
            align-items: center;
            margin-bottom: 1rem;

            > * {
                margin-top: 0;
                margin-bottom: 0;
            }
        }

        ${media.m`
            display: flex;
            margin-bottom: 3rem;
            align-items: center;

            ${TitleHighlight} {
                flex-grow: 0;
                flex-shrink: 1;
                margin-bottom: 0;
                margin-right: 2rem;
            }

            h3 {
                margin-bottom: 0;
            }
        `}
    }

    ${mediaMax.m`
        p {
            margin: 0;
        }
    `}

    ${Blockquote} {
        ${media.l`
            transform: translateX(123px);
        `}
    }
`;

const IconList = styled.ul`
    margin: 0;
    padding: 0;
    list-style: none;

    ${media.m`
        display: flex;
        flex-wrap: wrap;
    `}

    li {
        display: flex;
        align-items: center;
        padding: 0.5rem 0;
        padding-right: 4rem;
        font-weight: 500;
    }

    svg {
        width: 24px;
        height: 21px;
        margin-right: 1rem;

        & #tick {
            fill: ${({ theme }) => theme.colors.pink.color};
        }
    }
`;

const ServiceListWrapper = styled.div`
    display: flex;
    align-items: flex-end;

    .checklist {
        display: none;
        width: 123px;

        ${media.m`
            display: block;
        `}

        ${media.l`
            margin-right: -123px;
        `}
    }
`;

const CTAWrapper = styled.div`
    margin: 0 auto;
    margin-bottom: 5rem;

    ${media.m`
        margin-bottom: 10rem;
    `}

    ${media.l`
        max-width: 85.6rem;
    `}

    ${media.xl`
        max-width: 105.6rem;
    `}
`;

interface IHelpingOrganisationsFillAGapInATeam extends LayoutProps {
    data: {
        site: QuerySiteArgs;
    };
}

const HelpingOrganisationsFillAGapInATeam = ({
    data
}: IHelpingOrganisationsFillAGapInATeam) => {
    return (
        <>
            <Helmet>
                <title>How we help organisations fill a gap in their team with proven expertise</title>
                <meta
                    name="description"
                    content="Here's how we'll curate a bespoke team of experts to accelerate your innovation"
                />

                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:site" content="@wemakewaves" />

                <meta
                    name="twitter:title"
                    content="Do you want to fill a gap in your team with proven expertise?"
                />
                <meta
                    name="twitter:description"
                    content="Here's how we'll curate a bespoke team of experts to accelerate your innovation"
                />

                <meta
                    name="twitter:image"
                    content={`${
                        data.site.siteMetadata.siteUrl
                    }${socialImage}`}
                />

                <meta
                    property="og:title"
                    content="Do you need to fill a gap in your team with proven expertise?"
                />

                <meta
                    property="og:description"
                    content="Here's how we'll curate a bespoke team of experts to accelerate your innovation"
                />
                <meta property="og:type" content="website" />
                <meta
                    property="og:url"
                    content={`${
                        data.site.siteMetadata.siteUrl
                    }/helping-organisations-fill-a-gap-in-a-team`}
                />
                <meta
                    property="og:image"
                    content={`${
                        data.site.siteMetadata.siteUrl
                    }${socialImage}`}
                />
            </Helmet>
            <FixedMasthead />

            <BannerHeader>
                <Constrain type="narrow">
                    <div className="inner">
                        <LineTitle color="white">
                            How we help organisations
                        </LineTitle>

                        <ShownOnDesktop>
                            <LinkArrow
                                to="/helping-organisations-improve-an-existing-process"
                                color="white"
                                arrowColor="pink"
                            >
                                <LinkText underlineColor="pink">
                                    You want to build software to improve an
                                    existing process
                                </LinkText>
                            </LinkArrow>
                        </ShownOnDesktop>
                    </div>
                </Constrain>
            </BannerHeader>

            <PageHeader
                highlightColor="electricBlue"
                title="You want to fill a gap in your team with proven expertise."
            />

            <Constrain type="narrow">
                <Header>
                    <RelatableHeading>
                        <div>
                            <h2>
                                To deliver your innovation you need to fill a gap in your team
                            </h2>
                            <p>
                                Maybe your team doesn't have all the expertise it needs in order to deliver your innovation? Perhaps it isn’t desirable to extend your in-house team's capabilities or you need additional strategic input?
                            </p>
                        </div>
                        <div className="illustration">
                            <img
                                src={puzzle}
                                alt="puzzle convoluted manual processes resulting in errors"
                            />
                        </div>
                    </RelatableHeading>
                </Header>

                <HighlightHeader>
                    <mark>
                        Let us show you how we'll curate a bespoke team of experts, focussed on collaborating with you to accelerate your innovation
                    </mark>
                </HighlightHeader>
                <WhatWeDoSection>
                    <div className="header">
                        <TitleHighlight>
                            <GuthenHeadingLevel3>
                                Leading the process
                            </GuthenHeadingLevel3>
                        </TitleHighlight>

                        <h3>
                            A strategic partner with a product-led focus on delivery
                        </h3>
                    </div>

                    <p>
                       Working alongside your existing team, we’ll introduce appropriate tools and methods to drive our collaboration through shared artifacts and transparent communication. Then, by leveraging our expertise in product development we’ll introduce appropriate elements from Agile and Lean methods, accelerating rapid iteration towards your chosen outcomes.
                    </p>
                    <ServiceListWrapper>
                        <div>
                            <GuthenHeadingLevel4>
                                You'll hear us talk about
                            </GuthenHeadingLevel4>

                            <CheckList items={[
                                'Agile',
                                'Kanban',
                                'User story mapping',
                                'Collaborative design',
                                'Behaviour-driven development',
                            ]}/>

                        </div>

                        <img
                            src={checklistIllustration}
                            alt="Sketch of a checklist"
                            className="checklist"
                        />
                    </ServiceListWrapper>

                    <AndDivider />

                    <EvidencePullout
                        illustration={telensaIllustration}
                        linkTo="/case-studies/telensa-planet-street-lighting-system"
                        text="How our approach to product delivery brought the Telensa team together to start a new product"
                    />                      
                </WhatWeDoSection>

                <WhatWeDoSection>
                    <div className="header">
                        <TitleHighlight>
                            <GuthenHeadingLevel3>
                                Beautifully designed, expertly built
                            </GuthenHeadingLevel3>
                        </TitleHighlight>

                        <h3>
                            User centred mobile and web experiences, built for the future
                        </h3>
                    </div>

                    <p>
                        Our design process is a collaborative affair, a combination of research, customer feedback and the folks closest to the problem, working with us on ideation. We’ll create concepts for early user testing, refining the user experience based on feedback and then add the visual flourishes so the product not only meets user needs but also looks the part. 
                    </p>
                    <p>
                        In the code, we’ll ensure the software does what it’s intended to by collaboratively defining the requirements using behaviour driven development. The quality of the code is guided by our test-driven development approach and our drive to automate as much of the quality assurance as possible.
                    </p>
                    <ServiceListWrapper>
                        <div>
                            <GuthenHeadingLevel4>
                                You'll hear us talk about
                            </GuthenHeadingLevel4>

                            <CheckList items={[
                                'User experience design',
                                'Visual design',
                                'Collaborative design',
                                'Design sprint',
                                'Test-driven development',
                                'Behaviour-driven development',
                                'Continuous integration',
                            ]}/>

                        </div>

                        <img
                            src={checklistIllustration}
                            alt="Sketch of a checklist"
                            className="checklist"
                        />
                    </ServiceListWrapper>

                    <AndDivider />

                    <EvidencePullout
                        illustration={indevcoIllustration}
                        linkTo="/case-studies/indevco-customer-portal"
                        text="How our design and user experience expertise filled a skills gap in INDEVCO’s product team"
                    />                         
                </WhatWeDoSection>
                
                <WhatWeDoSection>
                    <div className="header">
                        <TitleHighlight>
                            <GuthenHeadingLevel3>
                                Whole system approach
                            </GuthenHeadingLevel3>
                        </TitleHighlight>

                        <h3>
                            A whole system approach to drive tangible change
                        </h3>
                    </div>

                    <p>
                        Our whole system approach ensures the contributions we make focus on maximising the value of the system as a whole and not just the parts we’re responsible for. We’ll help by identifying dependencies between different parts of the system and defining early on how these parts will need to interact. We’ll collaborate with you to define the API’s required and decouple our development so we can work independently by mocking out the interfaces. They’ll also be a suite of automated integration tests to make sure the different parts of the system work well together.
                    </p>
                    
                    <Blockquote>
                        The performance of a system doesn’t depend on how the parts perform taken separately, it depends on how they perform together.
                        <cite>Russell Lincoln Ackoff</cite>
                    </Blockquote>

                    <ServiceListWrapper>
                        <div>
                            <GuthenHeadingLevel4>
                                You'll hear us talk about
                            </GuthenHeadingLevel4>

                            <CheckList items={[
                                'API',
                                'Swagger',
                                'Systems thinking',
                                'Integration testing',
                                'Quality assurance',
                            ]}/>

                        </div>

                        <img
                            src={checklistIllustration}
                            alt="Sketch of a checklist"
                            className="checklist"
                        />
                    </ServiceListWrapper>

                    <AndDivider />
                    
                    <EvidencePullout
                        illustration={thomsonLocalIllustration}
                        linkTo="/case-studies/thomson-local-myorderbuilder"
                        text="How we collaborated with Thomson Local’s backend engineers on an API specification"
                    />                             
                </WhatWeDoSection>

                <CTAWrapper>
                    <DarkCTA>
                        <div>
                            <h4>We can also help you&hellip;</h4>
                            <p>
                                <CTALink>
                                    <LinkArrow
                                        to="/helping-organisations-improve-an-existing-process"
                                        arrowColor="electricBlue"
                                    >
                                        <LinkText underlineColor="electricBlue">
                                            Build software to improve an existing process
                                        </LinkText>
                                    </LinkArrow>
                                </CTALink>
                            </p>
                        </div>

                        <img
                            src={improveAProcessIllustrationWhite}
                            alt="Illustration ship being held back by an old computer"
                        />
                    </DarkCTA>
                </CTAWrapper>
            </Constrain>

            <SwellWrapper
                title="Next steps."
                subtitle="Speak with one of our founders"
            >
                <BookACall
                    calendlyUrl="https://calendly.com/we-make-waves/organisations-filling-a-gap?background_color=003d4c&text_color=ffffff&primary_color=00eae9"
                    pageUrl="helping-organisations-fill-a-gap-in-a-team"
                />
            </SwellWrapper>

            <CallToContact />
        </>
    );
};

export const query = graphql`
    query {
        site {
            siteMetadata {
                siteUrl
            }
        }
    }
`;

export default withLayout(HelpingOrganisationsFillAGapInATeam);
