import React from 'react';

import { media } from '../../utilities';
import styled from '../../styled-components';
import { LinkArrow, pullBackWide } from '../ui';

const Wrapper = styled.div`
    ${media.m`
        display: flex;
        align-items: center;
    `}

    ${media.l`
        margin-left: ${pullBackWide};
        /* bit arbitrary but just want to stop it running the full length */
        padding-right: 15%;
    `}

    h4 {
        margin: 0;
    }

    img {
        margin-bottom: ${({ theme }) => theme.spacing.m};

        ${media.m`
            width: 33%;
            margin-bottom: 0;
            margin-right: ${({ theme }) => theme.spacing.m};
        `}
        ${media.l`
            margin-right: ${({ theme }) => theme.spacing.l};
        `}
    }
`;

export const EvidencePullout: React.FunctionComponent<{
    linkTo: string;
    illustration: string;
    text: string;
}> = props => {
    const { illustration, linkTo, text } = props;
    return (
        <Wrapper>
            <img src={illustration} alt="" />
            <h4>
                <LinkArrow
                    to={linkTo}
                    arrowColor="electricBlue"
                    color="deepBlue"
                    keyline={true}
                >
                    {text}
                </LinkArrow>
            </h4>
        </Wrapper>
    );
};
